<template>
  <div class="con-down" v-if="!isWeChatBrowser">
      <div class="big-h2">欲言</div>
      <div class="small-h3">更懂中国人的ChatGPT</div>
      <span class="size-small">以ChatGPT为核心，通过自然语言处理。</span>
      <span class="size-small">让提问方式和结果更贴近中文使用习惯</span>
      <img v-if="!isMobile" src="../../../src/assets/images/qrcode-download.png" alt="welcomePage" class="we-mobile-img">
      <span v-if="!isMobile" class="we-txt">扫我下载</span>
      <img v-if="isMobile" src="../../../src/assets/images/mobile/welcomepage.png" alt="welcomePage" class="we-page-img">
      <div v-if="isMobile" class="btn" @click="_toDownLoadApp">下载app</div>
  </div>
  <div class="con-down" style="background: #f8f8f8" v-else>
    <img src="../../../src/assets/images/mobile/weChatWebBrowser.jpeg" alt="welcomePage" class="we-chat-img">
    <img src="../../../src/assets/images/mobile/welcomepage.png" alt="welcomePage" class="we-page-img">
  </div>
</template>

<script>
import Message from "vue-m-message";

const {body} = document
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Download",
  data() {
    return {
      isMobile: false,
      isWeChatBrowser: false,
      isAndroid: false
    }
  },
  mounted() {
    this._appSource()
  },
  beforeMount() {
    this._resizeHandler()
    window.addEventListener('resize', this._resizeHandler)
  },
  methods: {
    _resizeHandler() {
      const rect = body.getBoundingClientRect()
      if (rect.width <= 767) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
      this.screenWidth = rect.width
    },
    _appSource() {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      this.isAndroid = !isiOS
    },
    _isWeChatWebBrowser() {
      const ua = window.navigator.userAgent.toLowerCase();
      this.isWeChatBrowser = ua.match(/MicroMessenger/i) == "micromessenger"
    },
    _toDownLoadApp() {
      this._isWeChatWebBrowser()
      if (this.isWeChatBrowser) {
        return
      }

      if (this.isAndroid) {
        window.open('http://download.mfang.xyz/%E6%AC%B2%E8%A8%80ai-android-1.0.4.apk')
      } else {
        Message.info('敬请期待')
        // window.open('https://itunes.apple.com/app/id1271022527?mt=8')
      }
    }
  }
}
</script>
<style scoped>
.con-down {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to top, #9ae5e2, #189b90);
}

.big-h2 {
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
  margin: 30px 0 6px 0;
}

.small-h3 {
  font-size: 18px;
  color: #ffffff;
  margin: 6px 0;
}

.size-small {
  font-size: 14px;
  color: #ffffff;
  margin: 6px 0;
}

.we-page-img {
  margin-top: 20px;
  width: 50%;
  object-fit: contain;
  border-radius: 20px;
  box-shadow: 4px 5px 8px #9ae5e2;
}

.we-chat-img {
  width: 100%;
  object-fit: fill;
}

.btn {
  width: 180px;
  /*height: 50px;*/
  padding: 10px 0;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #9ae5e2;
  font-size: 20px;
  margin-top: 20px;
  font-weight: bold;
  color: #ffffff;
  background-image: linear-gradient(to left top, #189b90, #9ae5e2);
  cursor: pointer;
}

.we-txt {
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  margin-top: 20px;
}

.we-mobile-img {
  margin-top: 100px;
  max-height: 600px;
  width: 20%;
  object-fit: contain;
  border-radius: 6px;
}
</style>