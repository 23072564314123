<template>
  <router-view/>
</template>

<style>
@import "assets/fonts/iconfont.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  background: #f2f2f2;
}

* {
  padding: 0;
  margin: 0;
}

body {
  width: 100%;
  height: 100%;
  font-family: song-ti, hei-ti;
  padding: 0;
}

html {
  width: 100%;
  height: 100%;
}

.main-background-color {
  background: #11B1A5;
}

.icon, .iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
</style>
