<template>
  <div class="con">
    <div class="abs-con" v-if="showAlert" @click="_showOrHideAlert">
      <div class="abs-con-dialog-confirm-mobile" @click="_handlePrevent">
        <img src="../../../src/assets/images/mobile/cell-1.png" style="width: 199px;height: auto;margin-bottom: 20px;"
             alt="cell1"/>
        <span style="margin: 10px 0;font-size: 22px;font-weight: bold;color: #F75555">退出</span>
        <span style="margin: 4px 0;font-size: 13px;font-weight: bold;color: #999999">确定退出当前账号？</span>
        <div class="abs-btn-other-mobile" style="background: #11B1A5;margin-top: 30px;" @click="_logOut">是，退出</div>
        <div class="abs-btn-other-mobile" style="background: #999999" @click="_showOrHideAlert">否，取消</div>
      </div>
    </div>

    <div class="setting-con-row" style="margin-top: 14px;margin-bottom: 14px;">
      <div @click="_backPage">
        <i class="icon iconfont icon-xitongfanhui" style="font-size: 20px;color: #333333"></i>
        <span class="title-span">设置</span>
      </div>
    </div>
    <div class="setting-con-row" style="border-bottom: 1px solid #f2f2f2;color: #333333;" @click="_toSharePage">
      <div class="btn-con">
        <i class="icon iconfont icon-meiyuan" style="font-size: 26px;"></i>
      </div>
      <span style="font-weight: bold;font-size: 20px;margin-left: 10px;">分享赚取时长</span>
    </div>
    <div class="setting-con-row" style="border-bottom: 1px solid #f2f2f2;color: #333333;" @click="_toChongZhi">
      <div class="btn-con">
        <i class="icon iconfont icon-icon-test" style="font-size: 20px;"></i>
      </div>
      <span style="font-weight: bold;font-size: 20px;margin-left: 10px;">充值</span>
    </div>
    <div class="setting-con-row" style="border-bottom: 1px solid #f2f2f2;" @click="_logOut">
      <div class="btn-con" style="background: #ffffff">
        <i class="icon iconfont icon-tuichu" style="font-size: 28px;color: #F75555"></i>
      </div>
      <span style="font-weight: bold;font-size: 20px;margin-left: 10px;">退出</span>
    </div>
  </div>
</template>

<script>
import http from "@/util/http";
import Message from "vue-m-message"

export default {
  name: "SettingPage",
  data() {
    return {
      showAlert: false
    }
  },
  mounted() {
  },
  methods: {
    _handlePrevent() {
      event.stopPropagation()
    },
    _showOrHideAlert() {
      this.showAlert = !this.showAlert
    },
    _toChongZhi() {
      this.$router.push({
        path: '/ChongZhi'
      })
    },
    _toSharePage() {
      this.$router.push({
        path: '/ShareToBuyTime'
      })
    },
    _backPage() {
      this.$router.go(-1)
    },
    _logOut() {
      http.post('logOut')
          .then((res) => {
            if (res.data.status === 0) {
              Message.success(res.data.content)
              setTimeout(() => {
                this.$router.push({
                  path: '/'
                })
              }, 1000)
            } else {
              Message.error(res.data.content)
            }
          })
    },
  }
}
</script>

<style scoped>

.abs-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}

.con {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  position: relative;
}

.setting-con-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  margin: 0 5%;
  height: 70px;
}

.title-span {
  margin-left: 14px;
  font-size: 20px;
  color: #333333;
  font-weight: bold;
}

.cell-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
}

.abs-btn-other-mobile {
  width: 70%;
  height: 50px;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}

.abs-con-dialog-confirm-mobile {
  width: 80%;
  border-radius: 30px;
  background: #ffffff;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn-con {
  width: 36px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14px;
  background: #11B1A5;
  border-radius: 18px;
}
</style>