<template>
  <div class="con">
    <div class="abs-con" v-if="showPayResult">
      <div class="abs-con-dialog" style="" @click="_handlePrevent">
        <div class="abs-top">
          <span>充值结果</span>
        </div>
        <div class="pay-cost-con" style="margin: 20px 0 10px 0;">
          <span>当前余额: </span>
          <span style="margin-left: 10px;">{{ yuE }} 元</span>
        </div>
        <div class="abs-con-pay qr-container" style="cursor: no-drop" v-if="parResultInfo.pp_pay_success_time != 0" >
          <i class="icon iconfont icon-chenggong i-size" style="color: #63a35c"></i>
          <span class="i-result">充值成功</span>
        </div>
        <div class="abs-con-pay qr-container" style="cursor: no-drop" v-else >
          <i class="icon iconfont icon-chaxun i-size" style="color: #ffaa00"></i>
          <span class="i-result">查询充值结果...</span>
        </div>
        <div class="close-btn" @click="_showOrHidePayResult">
          <span class="">确认关闭</span>
        </div>
<!--        &lt;!&ndash;        <div class="close-btn" @click="_queryOrderDetail">&ndash;&gt;-->
<!--        &lt;!&ndash;          <span class="">重新获取订单状态</span>&ndash;&gt;-->
<!--        &lt;!&ndash;        </div>&ndash;&gt;-->
      </div>
    </div>
    <div class="con-row" style="margin-top: 14px;margin-bottom: 14px;">
      <div @click="_backPage">
        <i class="icon iconfont icon-xitongfanhui" style="font-size: 20px;color: #333333"></i>
        <span class="title-span">充值</span>
      </div>
    </div>
    <div class="con-row" style="justify-content: center">
      <img src="../../../src/assets/images/mobile/cell-0.png" style="width: 240px;height: auto;margin: 10px"
           alt="cell0">
    </div>
    <div class="con-row">
      <span class="span-tip">请选择充值金额 ：</span>
    </div>
    <div class="pay-con">
      <div v-for="(item, index) in listPay" :key="index"
           :class="item.select ? 'pay-con-child pay-con-child-select' : 'pay-con-child pay-con-child-un-select'"
           @click="_selectItemPay(item)">
        <img src="../../../src/assets/images/mobile/payChong.png" class="img-chong" alt="chong"/>
        <div class="right-span">
            <span class="big-money">为本账号充值
              <span class="big-pay">{{ item.pay }}</span>
              元</span>
          <span v-if="item.des" style="margin-top: 10px;">{{ item.des }}</span>
        </div>
      </div>
    </div>
    <div class="con-row" style="justify-content: center">
      <div class="pay-btn" @click="_makePay">
        支付
      </div>
    </div>
    <div v-html="domPay"/>
  </div>
</template>

<script>
import http from "@/util/http";
import Message from "vue-m-message";
let interval

export default {
  name: "ChongZhi",
  data() {
    return {
      queryPayData: {
        payId: undefined,
        pay: undefined,
        des: undefined,
        orderTradeNo: undefined
      },
      domPay: undefined,
      parResultInfo: {
        pp_kit_name: '',
        pp_order_id: '',
        pp_pay_success_time: '',
        pp_price: ''
      },
      totalTimesInterval: 0,
      listPay: [],
      showPayResult: false,
      yuE: 0
    }
  },
  unmounted() {
    interval && clearInterval(interval)
  },
  mounted() {
    this._payInfoList()
    this._userYuE()
  },
  methods: {
    _userYuE() {
      http.get('userYuE')
          .then((res) => {
            if (res.data.status === 0) {
              this.yuE = res.data.data
            }
          })
    },
    _queryOrder() {
      http.get('queryOrder', {
        orderId: this.queryPayData.orderTradeNo
      })
          .then((res) => {
            if (res.data.status === 0) {
              this._queryOrderDetail()
            } else if (res.data.status === 301) {
              this._queryOrderDetail()
            }
          })
    },
    _queryOrderDetail() {
      http.get('orderDetail', {
        orderId: this.queryPayData.orderTradeNo
      })
          .then((res) => {
            if (res.data.status === 0) {
              this.parResultInfo = res.data.data
            }
            this._userYuE()
            if (this.showPayResult) {
              return
            }
            this._showOrHidePayResult()
          })
    },
    _showOrHidePayResult() {
      this.showPayResult = !this.showPayResult

      if (!this.showPayResult) {
        this._resetPayResult()
      }

    },
    _handlePrevent() {
      event.stopPropagation()
    },
    _resetPayResult(){
      this.parResultInfo = {
        pp_kit_name: '',
        pp_order_id: '',
        pp_pay_success_time: '',
        pp_price: ''
      }
      interval && clearInterval(interval)
    },
    _makePay() {
      Message.loading("提交中")
      http.post('makePay', {
        kitId: this.queryPayData.payId,
        isMobile: 1
      })
          .then((res) => {
            Message.closeAll()
            if (res.data.status === 0) {
              Message.success(res.data.content)
              const outTradeNo = res.data.data.outTradeNo
              let resData = res.data.data.data
              const resDataArray = resData.split("name='alipaysubmit'")
              resData = resDataArray[0] + ' target="_blank" name="alipaysubmit" ' + resDataArray[1]
              this.queryPayData.orderTradeNo = outTradeNo
              this.domPay = resData

              setTimeout(() => {
                document.forms['alipaysubmit'].submit()
                this._showOrHidePayResult()

                const that = this
                interval = setInterval(() => {
                  that.queryPayData.orderTradeNo = outTradeNo
                  that._queryOrder()
                  that.totalTimesInterval = that.totalTimesInterval + 1
                  if (that.totalTimesInterval > 10) {
                    clearInterval(interval)
                  }
                }, 3999)

              }, 500)
            } else {
              Message.error(res.data.content)
            }
          })
    },
    _backPage() {
      this.$router.go(-1)
    },
    _selectItemPay(e) {
      for (const i in this.listPay) {
        if (this.listPay[i].id === parseInt(e.id)) {
          this.listPay[i].select = true
          this.queryPayData.des = this.listPay[i].des
          this.queryPayData.payId = this.listPay[i].id
          this.queryPayData.pay = this.listPay[i].pay
        } else {
          this.listPay[i].select = false
        }
      }
    },
    _payInfoList() {
      http.get('payInfoList')
          .then((res) => {
            if (res.data.status === 0) {
              this.listPay = []
              for (const i in res.data.data) {
                const obj = {
                  id: res.data.data[i].pk_id,
                  des: res.data.data[i].pk_des,
                  pay: res.data.data[i].pk_price / 100,
                  select: parseInt(i) === 0
                }

                this.listPay.push(obj)
              }

              this.queryPayData.des = this.listPay[0].des
              this.queryPayData.pay = this.listPay[0].pay
              this.queryPayData.payId = this.listPay[0].id
            }
          })
    },
  }
}
</script>

<style scoped>
.con {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  position: relative;
}
.abs-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}
.abs-con-dialog {
  max-width: 400px;
  width: 80%;
  max-height: 500px;
  padding-bottom: 40px;
  border-radius: 20px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.con-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  margin: 0 5%;
  height: auto;
}

.title-span {
  margin-left: 14px;
  font-size: 20px;
  color: #333333;
  font-weight: bold;
}

.span-tip {
  font-size: 16px;
  color: #333333;
  margin: 10px 0;
}

.pay-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  overflow-y: scroll;
  width: 90%;
}

.pay-con-child {
  width: 78%;
  padding: 0 6%;
  min-height: 90px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.pay-con-child-un-select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #ffffff;
}

.pay-con-child-select {
  border: 1px solid #11B1A5;
  background: rgba(17, 177, 165, 0.2);
}

.img-chong {
  width: 70px;
  height: 70px;
  margin-right: 20px;
}

.right-span {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  color: #11B1A5;
  font-weight: bold;
}

.big-pay {
  font-weight: bold;
  font-size: 28px;
  color: #FF4D67;
}
.pay-btn {
  width: 90%;
  height: 50px;
  border-radius: 25px;
  color: #ffffff;
  background: #11B1A5;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}
.abs-top {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 50px;
  min-height: 50px;
  background: #11B1A5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
}
.pay-cost-con {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  margin-top: 10px;
}
.abs-con-pay {
  padding: 10px 3% 10px 3%;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.qr-container {
  padding: 20px 0;
  margin-top: 10px;
  background: #f2f2f2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.i-size {
  font-size: 30px;
}
.i-result {
  font-size: 28px;
  font-weight: bold;
  color: #666666;
  margin-left: 20px;
}

.close-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
  background: #11B1A5;
  padding: 0 20px;
  height: 40px;
  border-radius: 25px;
  margin-top: 30px;
  cursor: pointer;
}
</style>