<template>
  <div class="detail-con">
    <div class="detail-con-row" style="margin-top: 14px;margin-bottom: 14px;">
      <div @click="_backPage">
        <i class="icon iconfont icon-xitongfanhui" style="font-size: 20px;color: #333333"></i>
        <span class="title-span">{{
            !queryData.id ? '创建对话' : queryData.title
          }}</span>
      </div>
    </div>
    <div class="column-out" v-if="!queryData.id">
      <div class="detail-con-row" style="margin-top: 30px;margin-bottom: 10px;" v-if="!queryData.id">
        <img src="../../../src/assets/images/mobile/detail-icon-big.png" class="img-big" alt="big-icon">
      </div>
      <div class="detail-con-row" style="margin-top: 20px;">
        <div class="cell cell-robot">
          <span>有什么问题尽管和我说哦！！比如：</span>
        </div>
      </div>
      <div class="detail-con-row" style="margin-top: 20px;">
        <div class="cell cell-robot" style="flex-direction: column">
          <span class="example-title">{{ listExamples.banGong.label }}</span>
          <div class="example-inner-con-cell" v-for="(item, index) in listExamples.banGong.data" :key="index"
               @click="_selectExample(item)">
            <span class="example-span">{{ item.content }}</span>
            <i class="icon iconfont icon-xiangyoujiantou" style="color: #666666;font-size: 12px;"></i>
          </div>
        </div>
      </div>
      <div class="detail-con-row" style="margin-top: 20px;">
        <div class="cell cell-robot" style="flex-direction: column">
          <span class="example-title">{{ listExamples.shengHuo.label }}</span>
          <div class="example-inner-con-cell" v-for="(item, index) in listExamples.shengHuo.data" :key="index"
               @click="_selectExample(item)">
            <span class="example-span">{{ item.content }}</span>
            <i class="icon iconfont icon-xiangyoujiantou" style="color: #666666;font-size: 12px;"></i>
          </div>
        </div>
      </div>
      <div class="detail-con-row" style="margin-top: 20px;">
        <div class="cell cell-robot" style="flex-direction: column">
          <span class="example-title">{{ listExamples.xueXi.label }}</span>
          <div class="example-inner-con-cell" v-for="(item, index) in listExamples.xueXi.data" :key="index"
               @click="_selectExample(item)">
            <span class="example-span">{{ item.content }}</span>
            <i class="icon iconfont icon-xiangyoujiantou" style="color: #666666;font-size: 12px;"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="column-out" v-if="queryData.id" ref="scrollDiv">
      <div v-for="(item, index) in listChat" :key="index" class="detail-con-row"
           :style="!item.isMine ? 'justify-content: flex-start;margin: 10px;' : 'justify-content: flex-end;margin: 10px;'">
        <div :class="item.isMine ? 'chat-detail-inner cell-mine' : 'chat-detail-inner cell-robot'">
          <div class="span-detail-row" @click="_setClipboard(item.content)">
            <span class="span-detail-time">{{ item.createTime }}</span>
            <i class="icon iconfont icon-fuzhi1"
               style="margin-right: 4px;font-size: 22px;"/>
          </div>
          <span v-if="!item.isMine" style="font-size: 14px;margin-bottom: 20px;">本次问答共消耗token {{ item.totalTokens }}</span>
          <span class="span-content">
                  {{ item.content }}
          </span>
        </div>
      </div>
    </div>
    <div class="detail-con-row" style="height: 90px;margin-bottom: 10px">
      <input v-model="queryData.content" class="input-bw" placeholder="给小言输入消息..."/>
      <div class="btn-con" @click="_sseDoChat">
        <i class="icon iconfont icon-fasong" style="font-size: 24px;"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Message from "vue-m-message";
import http from "@/util/http";
import {EventSourcePolyfill} from "event-source-polyfill";
import Cookies from "js-cookie";

export default {
  name: "ChatDetail",
  data() {
    return {
      onCommitting: false,
      queryData: {
        id: undefined,
        content: undefined,
        title: undefined
      },
      listChat: [],
      listExamples: {
        banGong: {
          label: '办公',
          data: [
            {
              content: '用C语言写一个傅里叶变换'
            },
            {
              content: '写一个不少于1000字的年会发言稿'
            },
            {
              content: '如何处理同事间的合作关系'
            }
          ]
        },
        shengHuo: {
          label: '生活',
          data: [
            {
              content: '如何做一锅美味的红烧肉'
            },
            {
              content: '酸梅汤的做法'
            },
            {
              content: '如何去除烧水壶中的水垢'
            }
          ]
        },
        xueXi: {
          label: '学习',
          data: [
            {
              content: '解析一下麦当劳的数字转型'
            },
            {
              content: '分析一下美的成功的原因'
            },
            {
              content: '给一个一元二次方程的解法'
            }
          ]
        }
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.queryData.id = this.$route.query.id
      this.queryData.title = this.$route.query.title
      this._chatDetail()
    }
  },
  methods: {
    _selectExample(e) {
      if (this.onCommitting) {
        Message.warning("请等待回复")
        return
      }
      this.queryData.content = e.content
      this.listChat = []
      this._sseDoChat()
    },
    _sseDoChat(){
      if (this.onCommitting) {
        Message.warning("请等待回复")
        return
      }

      if (!this.queryData.content) {
        Message.warning("问题不能为空")
        return
      }

      const queryContent = JSON.parse(JSON.stringify(this.queryData.content))
      if (this.queryData.id) {
        this.queryData.content = undefined
      }
      this.onCommitting = true
      Message.closeAll()
      // 设置等待答案过程中的状态交互
      Message.loading("正在拼命获取答案...", {
        duration: -1
      })

      this.listChat.push({
        isMine: 1,
        name: '我',
        content: queryContent,
        id: undefined,
        type: undefined,
        createTime: this._changeDateFormat(new Date(), "yyyy-MM-dd hh:mm:ss")
      })

      const answer = {
        isMine: 0,
        name: '答',
        content: '正在拼命获取答案...',
        id: undefined,
        type: undefined,
        createTime: this._changeDateFormat(new Date(), "yyyy-MM-dd hh:mm:ss")
      }

      this.listChat.push(answer)
      // end
      this._handleScrollBottom()
      const that = this
      if (window.EventSource) {
        const eventSource = new EventSourcePolyfill(
            "https://yuyan.mfang.xyz/api/v1/commitSSE?id=" + this.queryData.id + "&content=" + encodeURIComponent(queryContent), {
              // 设置重连时间
              heartbeatTimeout: 60 * 60 * 1000,
              timeout: 60 * 60 * 1000,
              // 添加token
              headers: {
                'Box-From': 0,
                'chatgpt-token': Cookies.get('token')
              },
            });
        /*
         * open：订阅成功（和后端连接成功）
         */
        eventSource.addEventListener('open', function () {
          Message.closeAll()
        });
        /*
        * message：后端返回信息，格式可以和后端协商
        */
        eventSource.addEventListener('receiveData', function (e) {
          const dataR = JSON.parse(e.data)
          if (dataR.status === 0) {
            if (that.listChat[that.listChat.length - 1].content === '正在拼命获取答案...') {
              that.listChat[that.listChat.length - 1].content = ''
            }
            that.listChat[that.listChat.length - 1].content = that.listChat[that.listChat.length - 1].content + dataR.content
          } else if (dataR.status === 9000) { // 结束问答
            if (!that.queryData.id) {
              that.queryData.id = dataR.data.answer.cd_id
              that.$route.query.id = that.queryData.id
              that.queryData.content = undefined
              that.queryData.title = queryContent
              that._chatDetail()
            } else {
              const answer = {
                isMine: dataR.data.answer.cd_is_answer === 0,
                name: dataR.data.answer.cd_is_answer === 1 ? '答' : '我',
                content: dataR.data.answer.cd_content,
                id: dataR.data.answer.cd_id,
                type: dataR.data.answer.cd_type,
                createTime: dataR.data.answer.cd_create_time,
                totalTokens: dataR.data.answer.cd_total_tokens
              }
              that.listChat[that.listChat.length - 1] = answer
            }
            that.onCommitting = false
          } else {
            that.onCommitting = false
            Message.error(dataR.content)
          }
          that._handleScrollBottom()
          console.log(dataR)
          return 'receive'
          // const { status, msg } = e.data
        });

        /*
        * error：错误（可能是断开，可能是后端返回的信息）
        */
        eventSource.addEventListener('error', function (err) {
          console.log(err);
          // 类似的返回信息验证，这里是实例
          err && err.status === 401 && console.log('not authorized')

          eventSource.close();
        })
        /*
        * close：关闭（是断开，可能是后端返回的信息）
        */
        eventSource.addEventListener('close', function (res) {
          console.log(res);

          eventSource.close();
        })
      } else {
        console.log("你的浏览器不支持SSE~")
      }
    },
    _doTheChatDetail() {
      if (this.onCommitting) {
        Message.warning("请等待回复")
        return
      }

      if (!this.queryData.content) {
        Message.warning("问题不能为空")
        return
      }

      const queryContent = JSON.parse(JSON.stringify(this.queryData.content))
      if (this.queryData.id) {
        this.queryData.content = undefined
      }
      this.onCommitting = true
      Message.closeAll()
      Message.loading("正在拼命获取答案...", {
        duration: -1
      })
      this.listChat.push({
        isMine: 1,
        name: '我',
        content: queryContent,
        id: undefined,
        type: undefined,
        createTime: this._changeDateFormat(new Date(), "yyyy-MM-dd hh:mm:ss")
      })
      this._handleScrollBottom()
      http.post('commitAnswer', {
        id: this.queryData.id,
        content: queryContent
      })
          .then((res) => {
            Message.closeAll()
            this.onCommitting = false
            if (res.data.status === 0) {
              Message.success(res.data.content)
              const resData = res.data.data.answer
              if (!this.queryData.id) {
                this.queryData.id = resData.cd_cl_id
                this.$route.query.id = this.queryData.id
                this.queryData.content = undefined
                this.queryData.title = queryContent
                this._chatDetail()
              } else {
                const answer = {
                  isMine: resData.cd_is_answer === 0,
                  name: resData.cd_is_answer === 1 ? '答' : '我',
                  content: resData.cd_content,
                  id: resData.cd_id,
                  type: resData.cd_type,
                  createTime: resData.cd_create_time,
                  totalTokens: resData.cd_total_tokens
                }
                this.listChat.push(answer)
              }
              this._handleScrollBottom()
            } else {
              Message.error(res.data.content)
            }
          })
    },
    _changeDateFormat(date, fmt) {
      const o = {
        "M+": date.getMonth() + 1, // 月份
        "d+": date.getDate(), // 日
        "h+": date.getHours(), // 小时
        "m+": date.getMinutes(), // 分
        "s+": date.getSeconds(), // 秒
        "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
        "S": date.getMilliseconds() // 毫秒
      }
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length))
      for (const k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)))
      return fmt
    },
    _setClipboard(content) {
      Message.closeAll()
      this.$copyText(content)
      Message.success('复制成功')
    },
    _backPage() {
      this.$router.go(-1)
    },
    _handleScrollBottom() { // 滚动到底部
      this.$nextTick(() => {
        let scrollElem = this.$refs.scrollDiv;
        scrollElem.scrollTo({top: scrollElem.scrollHeight, behavior: 'smooth'})
      })
    },
    _chatDetail() {
      Message.closeAll()
      Message.loading("获取数据...")
      http.get('chatDetail', {
        id: this.queryData.id
      })
          .then((res) => {
            Message.closeAll()
            if (res.data.status === 0) {
              const listResult = []
              for (const i in res.data.data) {
                const resData = res.data.data[i]
                listResult.push({
                  isMine: resData.cd_is_answer === 0,
                  name: resData.cd_is_answer === 1 ? '答' : '我',
                  content: resData.cd_content,
                  id: resData.cd_id,
                  type: resData.cd_type,
                  createTime: resData.cd_create_time,
                  totalTokens: resData.cd_total_tokens
                })
              }

              this.listChat = listResult
              this._handleScrollBottom()
            }
          })
    },
  }
}
</script>

<style scoped>
.detail-con {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background: #ffffff;
}

.detail-con-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  margin: 0 5%;
}

.img-big {
  width: 140px;
  height: auto;
}

.cell {
  max-width: 80%;
  min-width: 80%;
  padding: 14px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  white-space: pre-wrap;
  border-radius: 10px;
}

.chat-detail-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 70%;
  border-radius: 10px;
  padding: 10px 20px;
}

.cell-robot {
  background: #f2f2f2;
  color: #333333;
}

.cell-mine {
  background: #11B1A5;
  color: #ffffff;
}

.example-title {
  width: 98%;
  text-align: start;
  padding: 0 1%;
  font-size: 20px;
  font-weight: bold;
}

.example-inner-con-cell {
  padding: 14px 0;
  border-bottom: 1px solid #999999;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.example-span {
  display: flex;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
}

.column-out {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}

.input-bw {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  height: 45px;
  border-radius: 6px;
  background: #f2f2f2;
  border: none;
  padding: 0 10px;
}

input:focus {
  outline: #11b1a5;
  border: 1px solid #11b1a5;
  background: rgba(17, 177, 165, 0.1);
}

.btn-con {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #11B1A5;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.title-span {
  margin-left: 14px;
  font-size: 20px;
  color: #333333;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.span-detail-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 34px;
  padding-top: 6px;
}

.span-detail-time {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
}

.span-copy {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.span-content {
  width: 100%;
  text-align: start;
  line-height: 33px;
  font-size: 18px;
  white-space: pre-wrap;
}
</style>