<template>
  <div class="con-out">
    <div v-if="showShare" class="con-out-back" @click="_showOrHideShare">
      <div v-if="pic" class="con-out-share" @click="_handlePrevent">
        <img :src="'data:image/png;base64,' + pic" class="img-haibao" alt="haibao">
        <div class="bottom-btn-con">
          <div class="bottom-btn" @click="_savePic">
            <span>保存图片</span>
          </div>
          <div class="bottom-btn" @click="_genShareUrl">
            <span>分享链接</span>
          </div>
        </div>
      </div>
    </div>
    <div class="con-ii" v-if="!isMobile">

      <div class="row-span">
        <span>欲言GPT  分享好友   获得使用额度</span>
        <div class="share-btn" style="font-size: 20px;background: #ea6666" @click="_goBack">返回</div>
      </div>
      <div class="con-li-center">
        <div class="row-cell">
          <span class="row-cell-txt" style="font-weight: bold">已获得奖励额度</span>
        </div>
        <div class="row-cell" style="justify-content: space-between;margin-top: 40px;margin-bottom: 40px;">
          <span class="row-cell-txt-large">￥ {{totalPrice}}</span>
          <div class="share-btn" style="font-weight: bold" @click="_showOrHideShare">分享</div>
        </div>
        <div class="row-cell">
          <span class="row-cell-txt" style="font-size: 14px;color: #666666">被邀请人注册并储值后，即可获得储值金额10%奖励，计入余额中</span>
        </div>
      </div>
      <div class="con-li-center" style="margin: 20px 0 80px 0;min-height: 400px;">
        <div class="row-cell" style="justify-content: center">
          <span class="row-cell-txt" style="font-weight: bold">邀约成功记录</span>
        </div>
        <div class="row-cell" style="justify-content: center;margin-top: 20px;">
          <div class="title-cell" style="width: 30%">
            <span>用户</span>
          </div>

          <div class="title-cell" style="width: 30%">
            <span>时间</span>
          </div>

          <div class="title-cell" style="width: 20%">
            <span>储值金额</span>
          </div>

          <div class="title-cell" style="width: 20%">
            <span>奖励</span>
          </div>
        </div>
        <div class="con-li-center-child">
          <div class="row-cell" v-for="(item, index) in userList" :key="index" style="margin: 10px 0">

            <div class="title-cell title-bold" style="width: 30%;">
              <div class="tl-span">
                <img src="../assets/images/mobile/cell-0.png" alt="" class="img-cell">
                <span>{{ item.mobile }}</span>
              </div>
            </div>

            <div class="title-cell title-bold" style="width: 30%;">
              <span>{{ item.dateTime }}</span>
            </div>

            <div class="title-cell title-bold" style="width: 20%;">
              <span>{{ item.jinE }}</span>
            </div>

            <div class="title-cell title-bold" style="width: 20%;">
              <span>{{ item.jiangLi }}</span>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="con-ii-mobile" v-else>
      <div class="con-li-center-mobile" style="margin-top: 70%;">
        <div class="row-cell">
          <span class="row-cell-txt" style="font-weight: bold;font-size: 14px;margin-bottom: 10px">已获得奖励额度</span>
        </div>
        <div class="row-cell" style="justify-content: space-between;margin-top: 4px;margin-bottom: 4px;">
          <span class="row-cell-txt-large-mobile">￥ {{totalPrice}}</span>
          <div class="share-btn" style="height: 30px;font-size: 14px;font-weight: bold" @click="_showOrHideShare">分享</div>
        </div>
        <div class="row-cell">
          <span class="row-cell-txt" style="font-size: 11px;color: #666666;margin-top: 10px;">被邀请人注册并储值后，即可获得储值金额10%奖励，计入余额中</span>
        </div>
      </div>
      <div class="con-li-center-mobile" style="flex: 1">
        <div class="row-cell" style="justify-content: center">
          <span class="row-cell-txt" style="font-size: 14px;">邀约成功记录</span>
        </div>
        <div class="row-cell" style="justify-content: center;">
          <div class="title-cell" style="width: 30%">
            <span>用户</span>
          </div>
          <div class="title-cell" style="width: 30%">
            <span>时间</span>
          </div>
          <div class="title-cell" style="width: 20%">
            <span>储值金额</span>
          </div>
          <div class="title-cell" style="width: 20%">
            <span>奖励</span>
          </div>
        </div>
        <div class="con-li-center-child">
          <div class="row-cell" v-for="(item, index) in userList" :key="index">
            <div class="title-cell title-bold-mobile" style="width: 30%;">
              <div class="tl-span">
                <!--              <img src="../assets/images/mobile/cell-0.png" alt="" class="img-cell">-->
                <span>{{ item.mobile }}</span>
              </div>
            </div>

            <div class="title-cell title-bold-mobile" style="width: 30%;">
              <span>{{ item.dateTime }}</span>
            </div>

            <div class="title-cell title-bold-mobile" style="width: 20%;">
              <span>{{ item.jinE }}</span>
            </div>

            <div class="title-cell title-bold-mobile" style="width: 20%;">
              <span>{{ item.jiangLi }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/util/http";
import Message from "vue-m-message";

const {body} = document

export default {
  name: "ShareToBuyTime",
  data() {
    return {
      isMobile: false,
      showShare: false,
      totalPrice: 0,
      pic: undefined,
      shareUrl: undefined,
      userList: []
    }
  },
  mounted() {
    this._shareTuiGuangList()
  },
  beforeMount() {
    this._resizeHandler()
    window.addEventListener('resize', this._resizeHandler)
  },
  methods: {
    _resizeHandler() {
      const rect = body.getBoundingClientRect()
      if (rect.width <= 767) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
      this.screenWidth = rect.width
    },
    _goBack() {
      window.close()
    },
    _showOrHideShare() {
      this.showShare = !this.showShare

      if (this.showShare) {
        this._genPic()
      }
    },
    _handlePrevent() {
      event.stopPropagation()
    },
    _genPic() {
      if (this.pic) {
        return
      }
      Message.loading("生成海报中...")
      http.post('genPic')
          .then((res) => {
            Message.closeAll()
            if (res.data.status === 0) {
              this.pic = res.data.data
            }
          })
    },
    _shareTuiGuangList() {
      Message.loading("查询中...")
      http.get('shareTuiGuangList')
          .then((res) => {
            Message.closeAll()
            if (res.data.status === 0) {
              const resData = []
              for (const i in res.data.data) {
                resData.push({
                  mobile: res.data.data[i].u_mobile,
                  dateTime: res.data.data[i].pp_create_time,
                  jinE: res.data.data[i].pp_price,
                  jiangLi: res.data.data[i].pp_price / 10
                })
                this.totalPrice = this.totalPrice + res.data.data[i].pp_price / 10
              }
              this.userList = resData
            }
          })
    },
    _setClipboard(content) {
      this.$copyText(content)
    },
    _genShareUrl() {
      Message.loading("生成分享链接...")
      http.post('genShareUrl')
          .then((res) => {
            Message.closeAll()
            if (res.data.status === 0) {
              Message.success('分享链接复制成功')

              this.shareUrl = res.data.data
              this._setClipboard(this.shareUrl)
            } else {
              Message.error('分享链接生成失败')
            }
          })
    },
    _savePic() {
      const base64 = 'data:image/png;base64,' + this.pic
      this.downloadFileByBase64(base64, 'yuYan-sharePic.png')
    },
    dataURLtoBlob(dataUrl) {
      const arr = dataUrl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], {type: mime})
    },
    downloadFileByBase64(base64, name) {
      const myBlob = this.dataURLtoBlob(base64)
      const myUrl = URL.createObjectURL(myBlob)
      this.downloadFile(myUrl, name)
    },
    downloadFile(url, name = 'yuYan-sharePic.png') {
      const a = document.createElement("a")
      a.setAttribute("href", url)
      a.setAttribute("download", name)
      a.setAttribute("target", "_blank")
      let clickEvent = document.createEvent("MouseEvents");
      clickEvent.initEvent("click", true, true);
      a.dispatchEvent(clickEvent)
    }
  }
}
</script>

<style scoped>
.con-out {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 100%;
}

.con-out-back {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  position: absolute;
}

.con-out-share {
  padding: 12px;
  border-radius: 40px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 300px;
  background-size: 100% 100%;
  position: relative;
  max-width: 80%;
}

.con-ii {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: url("../assets/images/shareToBuyTime.png") no-repeat;
  background-size: 100% 100%;
}

.con-ii-mobile {
  width: 100%;
  height: 100%;
  display: flex;
  max-height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: url("../assets/images/mobile/shareToBuyTime.png") no-repeat;
  background-size: 100% 100%;
}

.row-span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 50px;
  font-weight: bold;
  max-width: 1200px;
  min-width: 800px;
  width: 80%;
  color: #ffffff;
  margin: 60px 0 40px 0;
  text-shadow: 4px 5px 20px #333333;
}

.row-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 30px;
}

.row-cell-txt {
  font-size: 18px;
  color: #666666;
}

.row-cell-txt-large {
  font-size: 40px;
  font-weight: bold;
  color: #11B1A5;
}

.row-cell-txt-large-mobile {
  font-size: 28px;
  font-weight: bold;
  color: #11B1A5;
}

.share-btn {
  width: 100px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #11B1A5;
  cursor: pointer;
}

/*::-webkit-scrollbar {*/
/*  display: none;*/
/*}*/

.con-li-center {
  max-width: 1000px;
  min-width: 800px;
  width: 70%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px 40px;
  margin-bottom: 20px;
  margin-top: 50px;
  background: #ffffff;
  box-shadow: 4px 5px 20px #11B1A5;
}

.con-li-center-mobile {
  width: 70%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 15px;
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 4px 5px 20px #11B1A5;
  overflow: hidden;
}

.con-li-center-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  overflow-y: scroll;
  width: 100%;
}

.title-cell {
  text-align: center;
  font-size: 14px;
  color: #666666;
}

.title-bold {
  font-size: 16px;
  font-weight: bold;
}

.tl-span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.title-bold-mobile {
  font-size: 13px;
}

.img-cell {
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
}

.img-haibao {
  width: 100%;
  height: auto;
}

.bottom-btn-con {
  bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 65%;
  position: absolute;
}

.bottom-btn {
  width: 106px;
  background: #11B1A5;
  height: 35px;
  border-radius: 17.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
</style>