<template>
  <div class="con">
    <div class="con-row" style="margin-top: 14px;margin-bottom: 14px;">
      <div @click="_backPage">
        <i class="icon iconfont icon-xitongfanhui" style="font-size: 20px;color: #333333"></i>
        <span class="title-span">统计</span>
      </div>
    </div>
    <div class="con-row">
      <span class="yu-span-con" style="font-size: 20px;font-weight: bold;color: #333333">余额<span class="yu-span"
          :style="yuE > 0 ? 'color: #00CDBD' : 'color: #F75555'">{{ yuE }}</span>元</span>
      <div class="cz-btn" @click="_toChongZhiPage">充值</div>
    </div>
    <div class="con-row" style="margin: 20px 0 10px 0">
      <span class="yu-span-con" style="font-size: 14px;font-weight: bold;color: #333333">分享好友获取分成</span>
      <div class="cz-btn-shi" @click="_toSharePage" style="width: 100px;">赚取时长</div>
    </div>
    <div class="con-row" style="margin: 10px 0 10px 0;">
      <span style="font-size: 19px;font-weight: bold;color: #333333">历史统计</span>
    </div>
    <div class="con-row" style="margin-bottom: 20px;">
      <div v-for="(item, index) in listTokensTime" :key="index"
           :class="item.select ? 'token-con-cell token-con-cell-select' : 'token-con-cell token-con-cell-un-select'"
           @click="_selectItemToken(item)">
        <span>{{ item.label }}</span>
      </div>
    </div>
    <div class="token-con-column">
      <div v-for="(item, index) in tokenCostLists" :key="index" class="token-cost-con">
        <div class="token-con-column-cell">
          <img src="../../../src/assets/images/mobile/timer.png" class="small-timer" alt="timer"/>
          <span class="tokens-middle-label"> {{ item.dateLabel }} </span>
          <span class="span-right"> tokens {{ item.tokensTotal }}</span>
        </div>
        <!--            <i class="icon iconfont icon-xiangyoujiantou" style="color: #666666;font-size: 14px;"/>-->
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/util/http";
import Message from "vue-m-message";

export default {
  name: "TongJi",
  data() {
    return {
      yuE: 0,
      tokenCostLists:[],
      listTokensTime: [
        {
          label: '近1个月',
          type: 1,
          select: true
        },
        {
          label: '近3个月',
          type: 2,
          select: false
        },
        {
          label: '近半年',
          type: 3,
          select: false
        }
      ],
    }
  },
  mounted() {
    this._userYuE()
    this._tokensCostListDay()
  },
  methods: {
    _backPage() {
      this.$router.go(-1)
    },
    _toChongZhiPage() {
      this.$router.push({
        path: '/ChongZhi'
      })
    },
    _toSharePage() {
      this.$router.push({
        path: '/ShareToBuyTime'
      })
    },
    _userYuE() {
      http.get('userYuE')
          .then((res) => {
            if (res.data.status === 0) {
              this.yuE = res.data.data
            }
          })
    },
    _selectItemToken(e) {
      const label = e.label
      for (const i in this.listTokensTime) {
        if (this.listTokensTime[i].label === label) {
          this.listTokensTime[i].select = true
        } else {
          this.listTokensTime[i].select = false
        }
      }

      this._tokensCostListDay()
    },
    _tokensCostListDay() {
      Message.closeAll()
      Message.loading("加载中")
      let queryTypeTimes = undefined
      for (const i in this.listTokensTime) {
        if (this.listTokensTime[i].select) {
          queryTypeTimes = this.listTokensTime[i].type

          break
        }
      }
      this.tokenCostLists = []
      http.get('tokensCostListDay', {
        timeType: queryTypeTimes
      })
          .then((res) => {
            Message.closeAll()
            if (res.data.status === 0) {
              this.tokenCostLists = res.data.data
            }
          })
    },
  }
}
</script>

<style scoped>
.con {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  position: relative;
}

.title-span {
  margin-left: 14px;
  font-size: 20px;
  color: #333333;
  font-weight: bold;
}

.con-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  margin: 0 5%;
  height: 50px;
}
.yu-span-con {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}
.yu-span {
  margin: 0 10px;
  font-size: 30px;
}
.cz-btn {
  width: 80px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid #11B1A5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #11B1A5;
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}
.cz-btn-shi {
  width: 100px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid #11B1A5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #11B1A5;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}
.token-con-cell {
  width: 100px;
  height: 30px;
  border-radius: 8px;
  border: 1px solid #11B1A5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 20px 0 0;
  cursor: pointer;
}
.token-con-cell-select {
  color: #ffffff;
  font-weight: bold;
  background: #11B1A5;
}

.token-con-cell-un-select {
  color: #11B1A5;
  background: #ffffff;
}
.token-con-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  margin: 0 5%;
  height: 100%;
  overflow-y: scroll;
}
.token-cost-con {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 88px;
  min-height: 80px;
  width: 90%;
  padding: 0 2%;
  background: #ffffff;
  box-shadow: 4px 5px 8px #666666;
  border-radius: 20px;
  margin-bottom: 10px;
}
.small-timer {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  margin-left: 10px;
}
.token-con-column-cell {
  font-size: 18px;
  font-weight: bold;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}
.tokens-middle-label {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.span-right {
  font-size: 13px;
  color: #666666;
  margin-right: 10px;
}
</style>