<template>
  <div class="home">
    <div class="home-top" :style="screenWidth < 1560 ? 'min-height: 380px;' : ''" v-if="!isMobile">
      <img src="../../src/assets/images/homepage1.png" alt="homepage1" class="home-image">
      <div class="home-text">
        <div class="big-h2">欲言</div>
        <div class="small-h3">更懂中国人的ChatGPT</div>
        <span class="size-small">以ChatGPT为核心，通过自然语言处理。让提问方式和结果更贴近中文使用习惯</span>
        <div class="down-con">
          <div v-if="showQRCode" class="down-con-child">
            <img src="../../src/assets/images/qrcode-download.png" alt="qrcode" class="down-con-child-img"/>
          </div>
          <div class="btn" @click="_toLoginPage">登录网页版</div>
          <div v-if="!hasShare" class="btn-down" style="margin-left: 40px;" @mouseenter="_showOrHideQRCode" @mouseleave="_showOrHideQRCode">下载app</div>
        </div>
      </div>
    </div>
    <div class="cell-row" v-if="!isMobile">
      <div class="cell-row-left">
        <span class="big-text">欲言可以做什么</span>
        <span class="big-text-bottom">与人对话互动，回答问题，协助创作，高效便捷地帮助人们获取信息、知识和灵感。</span>
      </div>
      <div class="cell-row-right">
        <div v-for="(item, index) in listExamples" :key="index" class="cell-row-right-child"
             :style="index === listExamples.length - 1 ? 'border-bottom: 1px solid #999999;' : ''">
          <span>{{ item.content }}</span>
        </div>
      </div>
    </div>

    <div class="m-con-inner" v-if="isMobile">
        <div class="m-con-small-text m-size-row" style="margin-bottom: 140px;">
          <span>欲 言</span>
        </div>
        <div class="m-con-small-span" style="margin-bottom: 30px;">更懂中国人的ChatGPT</div>
        <div class="m-con-small-span-sm" style="margin-bottom: 20px;">以ChatGPT为核心，通过自然语言处理。让提问方式和结果更贴近中文使用习惯</div>
        <div class="m-con-btn" @click="_toLoginPage">登录网页版</div>
        <div v-if="!hasShare" class="m-con-btn-download" @click="_toDownloadPage">下载app</div>
    </div>
    <section v-if="isMobile">
      <div class="m-con-inner-bottom-con" style="margin-top: 60px;">
        <span class="big-tt">欲言可以做什么</span>
      </div>
      <div class="m-con-inner-bottom-con" style="margin-top: 30px;">
        <span class="small-tt">与人对话互动，回答问题，协助创作，高效便捷地帮助人们获取信息、知识和灵感。</span>
      </div>
      <div class="m-con-inner-bottom-con-column">
        <div v-for="(item, index) in listExamples" :key="index" class="m-con-inner-bottom-con-column-cell"
             :style="index !== listExamples.length - 1 ?'border-bottom: 1px solid #f2f2f2;' : ''">
          <span>{{ item.content }}</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

const {body} = document
export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      screenWidth: 1920,
      isMobile: false,
      hasShare: false,
      showQRCode: false,
      listExamples:
          [
            {
              content: '用C语言写一个傅里叶变换'
            },
            {
              content: '写一个不少于1000字的年会发言稿'
            },
            {
              content: '如何做一锅美味的红烧肉'
            },
            {
              content: '如何去除烧水壶中的水垢'
            },
            {
              content: '解析一下麦当劳的数字转型'
            },
            {
              content: '给一个一元二次方程的解法'
            }
          ]
    }
  },
  beforeMount() {
    this._resizeHandler()
    window.addEventListener('resize', this._resizeHandler)
  },
  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'shareUid') === true) {
      this.hasShare = true
    }
  },
  methods: {
    _showOrHideQRCode(){
      this.showQRCode = !this.showQRCode
    },
    _resizeHandler() {
      const rect = body.getBoundingClientRect()
      if (rect.width <= 767) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
      this.screenWidth = rect.width
    },
    _toLoginPage() {
      let shareUid = undefined

      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'shareUid') === true) {
        shareUid = this.$route.query.shareUid
      }

      this.$router.push({
        path: '/login',
        query: {
          shareUid: shareUid
        }
      })
    },
    _toDownloadPage() {
      this.$router.push({
        path: '/Download'
      })
    }
  }
}
</script>
<style>
.home {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
}

.home-top {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 500px;
}

.home-image {
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 1;
  object-fit: fill;
}

.home-text {
  position: absolute;
  z-index: 999;
  width: 76%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #ffffff;
}

.cell-row {
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 3%;
  padding-top: 100px;
}

.cell-row .cell-row-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 360px;
  flex-wrap: wrap;
  margin-right: 50px;
}

.cell-row .cell-row-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.cell-row .cell-row-right .cell-row-right-child {
  height: 50px;
  border-top: 1px solid #999999;
  /*border-bottom: 1px solid #999999;*/
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 10px;
  font-size: 20px;
  font-weight: bold;
  color: #666666;
}

.big-text {
  font-size: 50px;
  font-weight: bold;
  color: #333333;
}

.big-text-bottom {
  font-size: 18px;
  color: #666666;
  margin-top: 20px;
  text-align: start;
}

.big-h2 {
  font-size: 100px;
  font-weight: bold;
}

.small-h3 {
  font-size: 30px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.9);
}

.size-small {
  font-size: 20px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 30px;
}

.btn {
  width: 200px;
  /*height: 50px;*/
  padding: 15px 0;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #999999;
  font-size: 20px;
  margin-top: 50px;
  font-weight: bold;
  /*background: rgba(17, 177, 165, 0.4);*/
  /*background-image: linear-gradient(to left top, #02867b, #9ae5e2);*/
  background-image: linear-gradient(to left top, #999999, #333333);
  cursor: pointer;
}

.btn-down:hover {
  border: 1px solid #9ae5e2;
  /*color: #11B1A5;*/
  /*background: #ffffff;*/
  background-image: linear-gradient(to left top, #999999, #333333);
  cursor: pointer;
}
.btn-down {
  width: 200px;
  /*height: 50px;*/
  padding: 15px 0;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*border: 1px solid #999999;*/
  font-size: 20px;
  margin-top: 50px;
  font-weight: bold;
  /*background: rgba(17, 177, 165, 0.4);*/
  /*background-image: linear-gradient(to left top, #02867b, #9ae5e2);*/
  background-image: linear-gradient(to left top, #02867b, #9ae5e2);
  cursor: pointer;
}

.btn:hover {
  border: 1px solid #9ae5e2;
  /*color: #11B1A5;*/
  /*background: #ffffff;*/
  background-image: linear-gradient(to left top, #02867b, #9ae5e2);
  cursor: pointer;
}
.m-con-inner {
  width: 100%;
  height: 550px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../assets/images/homepage3.png");
}
.m-con-inner .m-con-small-text {
  color: #ffffff;
  font-size: 58px;
  font-weight: bold;
}
.m-size-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 243px;
}
.m-con-inner .m-con-small-span {
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
}
.m-con-small-span-sm {
  font-size: 16px;
  color: #ffffff;
  max-width: 240px;
  text-align: start;
  line-height: 26px;
}
.m-con-btn {
  width: 220px;
  height: 42px;
  border-radius: 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
  background-image: linear-gradient(to left top, #999999, #333333);
  border: 1px solid #999999;
}
.m-con-btn-download {
  width: 220px;
  height: 42px;
  border-radius: 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
  background-image: linear-gradient(to left top, rgba(17, 177, 165, 0.2), #f2f2f2);
  border: 1px solid rgba(17, 177, 165, 0.7);
}
.m-con-inner-bottom-con {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding: 0 4%;
}
.m-con-inner-bottom-con .big-tt {
  font-size: 30px;
  font-weight: bold;
  color: #333333;
}
.m-con-inner-bottom-con .small-tt {
  font-size: 16px;
  color: #666666;
  text-align: start;
  width: 80%;
  line-height: 30px;
}
.m-con-inner-bottom-con-column {
  width: 72%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 10px;
  margin: 30px 14%;
}
.m-con-inner-bottom-con-column-cell {
  height: 60px;
  display: flex;
  width: 90%;
  padding: 0 5%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.down-con {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.down-con-child {
  position: absolute;
  width: 200px;
  height: 200px;
  background: #ffffff;
  border-radius: 10px;
  z-index: 999;
  left: 450px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.down-con-child-img {
  width: 95%;
  height: 95%;
}
</style>