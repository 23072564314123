<template>
  <div v-if="!isMobile" class="con-out">
    <div class="abs-con" v-if="showEdit" @click="_showOrHideEdit">
      <div class="abs-con-dialog" @click="_handlePrevent">
        <div class="abs-top">
          <span>修改标题</span>
        </div>
        <input class="abs-input" v-model="queryData.title"/>
        <div class="abs-btn" @click="_editChatList">保 存</div>
      </div>
    </div>
    <div class="abs-con" v-if="showHistory" @click="_showOrHideHistory">
      <div class="abs-con-dialog absolute-aa" style="width: 40%;" @click="_handlePrevent">
        <div class="abs-top">
          <span>token 消耗记录</span>
        </div>
        <div class="token-con" style="margin: 30px 0">
          <div v-for="(item, index) in listTokensTime" :key="index"
               :class="item.select ? 'token-con-cell token-con-cell-select' : 'token-con-cell token-con-cell-un-select'"
               @click="_selectItemToken(item)">
            <span>{{ item.label }}</span>
          </div>
        </div>
        <div class="token-con-column">
          <div v-for="(item, index) in tokenCostLists" :key="index" class="token-cost-con">
            <div class="token-con-column-cell">
              <span style="margin-right: 40px;"> {{ item.dateLabel }} </span>
              <span> 消耗 {{ item.tokensTotal }} tokens</span>
            </div>
            <!--            <i class="icon iconfont icon-xiangyoujiantou" style="color: #666666;font-size: 14px;"/>-->
          </div>
        </div>
      </div>
    </div>
    <div class="abs-con" v-if="showPayResult">
      <div class="abs-con-dialog" style="width: 700px;" @click="_handlePrevent">
        <div class="abs-top">
          <span>充值结果</span>
        </div>
        <div class="pay-cost-con" style="margin: 20px 0 10px 0;">
          <span>当前余额: </span>
          <span style="margin-left: 10px;">{{ yuE }} 元</span>
        </div>
        <div class="abs-con-pay qr-container" style="cursor: no-drop" v-if="parResultInfo.pp_pay_success_time != 0">
          <i class="icon iconfont icon-chenggong i-size" style="color: #63a35c"></i>
          <span class="i-result">充值成功</span>
        </div>
        <div class="abs-con-pay qr-container" style="cursor: no-drop" v-else>
          <i class="icon iconfont icon-shibai i-size" style="color: #c60101"></i>
          <span class="i-result">充值失败</span>
        </div>
        <div class="close-btn" @click="_showOrHidePayResult">
          <span class="">确认关闭</span>
        </div>
        <!--        <div class="close-btn" @click="_queryOrderDetail">-->
        <!--          <span class="">重新获取订单状态</span>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="abs-con" v-if="showPay" @click="_showOrHidePay">
      <div class="abs-con-dialog" style="width: 700px;" @click="_handlePrevent">
        <div class="abs-top">
          <span>充值</span>
        </div>
        <div class="pay-cost-con" style="margin: 20px 0 10px 0;">
          <span>当前余额: </span>
          <span style="margin-left: 10px;">{{ yuE }} 元</span>
        </div>
        <div class="abs-con-pay">
          <div v-for="(item, index) in listPay" :key="index"
               :class="item.select ? 'abs-con-child abs-con-child-select' : 'abs-con-child abs-con-child-un-select'"
               @click="_selectItemPay(item)">
            <div class="money-con">
              <span class="small-money">￥</span>
              <span class="big-money">{{ item.pay }}</span>
            </div>
            <div v-if="item.des" class="abs-con-bottom">
              <span>{{ item.des }}</span>
            </div>
          </div>
        </div>
        <div class="abs-con-pay qr-container" style="cursor: no-drop">
          <div v-html="domPay"></div>
          <!--          <img src="../../src/assets/images/qrcode.png" class="qrcode-img" alt=""/>-->
          <!--          <div class="pay-column">-->
          <!--            <div class="pay-cell">-->
          <!--              <span class="small-money-size">￥</span>-->
          <!--              <span class="big-money-z">{{ queryPayData.pay }}</span>-->
          <!--              <span>{{ queryData.des }}</span>-->
          <!--            </div>-->
          <div class="pay-cell-alipay" style="margin-top: 20px;" @click="_makePCPay">
            <!--              <i class="icon iconfont icon-zhifubao" style="font-size: 25px;margin-right: 6px;"></i>-->
            <span style="font-size: 15px;">支付宝支付</span>
          </div>
          <!--          </div>-->
        </div>
      </div>
    </div>
    <div class="abs-con" v-if="showSetting" @click="_showOrHideSetting">
      <div class="abs-con-dialog" style="border-radius: 10px;" @click="_handlePrevent">
        <div class="abs-top"
             style="border-top-right-radius: 10px;border-top-left-radius: 10px;border-bottom-left-radius: 0;border-bottom-right-radius: 0;">
          <span>设置</span>
        </div>
        <div class="setting-con" style="margin-top: 30px;" @click="_logOut">
          <span class="setting-con-flex">退出</span>
          <i class="icon iconfont icon-xiangyoujiantou"></i>
        </div>
      </div>
    </div>
    <div class="abs-con" v-if="showAlert" @click="_showOrHideAlert">
      <div class="abs-con-dialog-confirm" @click="_handlePrevent">
        <div class="abs-top" style="background: #c60101">
          <span>确认删除,删除后不可恢复?</span>
        </div>
        <div class="abs-btn-other-con">
          <div class="abs-btn-other" style="background: #999999" @click="_showOrHideAlert">取消</div>
          <div class="abs-btn-other" style="background: #11B1A5" @click="_deleteChatList">确定</div>
        </div>
      </div>
    </div>
    <div class="left-con">
      <div class="d-cell round-row" @click="_addNewChatList">
        <i class="icon iconfont icon-jiahao" style="font-size: 14px;margin-right: 20px;"></i>
        <span style="font-size: 14px;">添加新对话</span>
      </div>
      <div class="left-con-inner">
        <div :class="item.select ? 'd-cell d-big-round detail-select' : 'd-cell d-big-round'"
             v-for="(item, index) in list" :key="index" @click="_selectListItem(item)">
          <span class="span-row-inner">{{ item.cl_name }}</span>
          <i class="icon iconfont icon-wenbenshuru" style="margin: 0 6px;" @click="_editChatListName(item)"></i>
          <i class="icon iconfont icon-shanchu" @click="_deleteChatListOut(item)"></i>
        </div>
      </div>
      <div style="width: 100%;text-align: center;padding: 10px 0;">
        <span style="color: #ffffff">v1.0.1</span>
      </div>
    </div>
    <div class="right-con">
      <div class="d-row">
        <div class="d-row-left">
        </div>
        <span style="margin-right: 20px;">{{ userInfo.u_mobile }}</span>
        <div class="qrcode-con" @mouseleave="_showOrHideQRCode" @mouseenter="_showOrHideQRCode">
          <div v-if="showQRCode" class="qrcode-con-child">
            <img src="../../src/assets/images/qrcode-download.png" alt="qrcode" class="qrcode-con-child-img"/>
          </div>
          <div class="share-con">下载app</div>
        </div>
        <div class="share-con" @click="_toShareToBuyTimePage">赚取时长</div>
        <div class="vip-con" @click="_showOrHidePay">
          <i class="icon iconfont icon-VIP span-row" style="margin-right: 4px;"></i>
          <span>余额 {{ yuE }}元</span>
        </div>
        <i class="icon iconfont icon-history span-row" style="margin-right: 6px;" @click="_showOrHideHistory"></i>
        <i class="icon iconfont icon-shezhi span-row" style="margin-right: 40px;font-size: 24px"
           @click="_showOrHideSetting"></i>
      </div>
      <div class="right-center-con">
        <!--          列表里的老详情-->
        <div v-if="!isNewAdd && this.list.length > 0" class="right-child-con" ref="scrollDiv">
          <div v-for="(item, index) in listChat" :key="index" class="chat-detail-cont">
            <div v-if="!item.isMine" class="is-host chat-img-src">
              {{ item.name }}
            </div>
            <div class="chat-detail-inner">
              <div class="chat-detail-top">
                <div class="chat-detail-top-left">
                  <span>{{ item.createTime }}</span>
                  <span v-if="!item.isMine" style="margin-left: 30px;">本次问答共消耗token {{ item.totalTokens }}</span>
                </div>
                <i class="icon iconfont icon-fuzhi1 copy-btn" @click="_setClipboard(item.content)"
                   style="margin-right: 20px;"/>
              </div>
              <div class="chat-detail-top">
                <span class="pp">
                  {{ item.content }}
                </span>
              </div>
            </div>
            <div v-if="item.isMine" class="is-not-host chat-img-src">
              {{ item.name }}
            </div>
          </div>
        </div>
        <!--          新增详情的提示案例-->
        <div v-if="isNewAdd || this.list.length === 0" class="right-child-con">
          <span class="example-biao-ti">使用欲言  您可以获得下面的能力</span>
          <div class="example-con">
            <span class="example-title">{{ listExamples.banGong.label }}</span>
            <div class="example-inner-con">
              <div class="example-inner-con-cell" v-for="(item, index) in listExamples.banGong.data" :key="index"
                   @click="_selectExample(item)">
                <span class="example-span">{{ item.content }}</span>
                <i class="icon iconfont icon-xiangyoujiantou" style="color: #666666;font-size: 14px;"></i>
              </div>
            </div>
          </div>
          <div class="example-con">
            <span class="example-title">{{ listExamples.shengHuo.label }}</span>
            <div class="example-inner-con">
              <div class="example-inner-con-cell" v-for="(item, index) in listExamples.shengHuo.data" :key="index"
                   @click="_selectExample(item)">
                <span class="example-span">{{ item.content }}</span>
                <i class="icon iconfont icon-xiangyoujiantou" style="color: #666666;font-size: 14px;"></i>
              </div>
            </div>
          </div>
          <div class="example-con">
            <span class="example-title">{{ listExamples.xueXi.label }}</span>
            <div class="example-inner-con">
              <div class="example-inner-con-cell" v-for="(item, index) in listExamples.xueXi.data" :key="index"
                   @click="_selectExample(item)">
                <span class="example-span">{{ item.content }}</span>
                <i class="icon iconfont icon-xiangyoujiantou" style="color: #666666;font-size: 14px;"></i>
              </div>
            </div>
          </div>
        </div>

        <!--        底部发送栏-->
        <div class="right-child-con-bottom">
          <!--          <input v-model="queryData.content" class="input-bottom" @keyup.enter="_doTheChatDetail"/>-->
          <!--          <div class="input-bottom-z" contenteditable placeholder="请输入文字" v-text="queryData.content" @input="_handleInput"></div>-->
          <div style="display: flex;flex: 1;" class="zz-textarea">
            <d-textarea class="zz-textarea" style="width: 90%;
                              border-radius: 30px;
                              border: 2px solid #f0f0f0;
                              padding: 10px 30px;
                              font-size: 16px;
                              font-weight: bold;
                              max-height: 100px;
                              color: #666666;
  white-space: pre-wrap;" v-model="queryData.content" autosize placeholder="请输入" @keydown.ctrl.enter="_sseDoChat"></d-textarea>
          </div>
          <div class="send-btn-con">
            <i class="icon iconfont icon-send send-btn" @click="_sseDoChat" ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="mobile">
    <div class="abs-con" v-if="showAlert" @click="_showOrHideAlert">
      <div class="abs-con-dialog-confirm-mobile" @click="_handlePrevent">
        <img src="../../src/assets/images/mobile/cell-1.png" style="width: 199px;height: auto;margin-bottom: 20px;"
             alt="cell1"/>
        <span style="margin: 10px 0;font-size: 22px;font-weight: bold;color: #333333">确认删除？</span>
        <span style="margin: 4px 0;font-size: 13px;font-weight: bold;color: #999999">您确定要删除此聊天记录吗？</span>
        <div class="abs-btn-other-mobile" style="background: #11B1A5;margin-top: 30px;" @click="_deleteChatList">是，删除
        </div>
        <div class="abs-btn-other-mobile" style="background: #999999" @click="_showOrHideAlert">否，取消</div>
      </div>
    </div>

    <div class="mobile-row mobile-row-first-line">
      <div class="mobile-row-first-line-child">
        <img src="../../src/assets/images/mobile/small-left-icon.png" class="mobile-small-logo" alt="bigLogo"/>
        <span>小言</span>
      </div>
      <div class="vip-con" @click="_toDownloadPage" style="margin-right: 410x;">
        <div class="share-con" style="width: 70px;">
          下载app
        </div>
      </div>
      <div class="vip-con" @click="_toChongZhiPage">
        <i class="icon iconfont icon-VIP span-row" style="margin-right: 4px;font-size: 22px;"></i>
        <span><span :style="yuE > 0 ? 'color: #00CDBD' : 'color: #F75555'">{{ yuE }}</span> 元</span>
      </div>
      <i class="icon iconfont icon-history span-row-mobile" style="margin-right: 10px;font-size: 24px;"
         @click="_toTongJiPage"></i>
      <i class="icon iconfont icon-shezhi span-row-mobile"
         @click="_toSettingPage"></i>
    </div>
    <div v-if="list.length === 0" class="mobile mobile-flex">
      <img src="../../src/assets/images/mobile/headLogo.png" class="mobile-big-logo" alt="bigLogo"/>
      <span style="font-size: 28px;font-weight: bold;color: #333333;margin-top: 30px;">欢迎，{{
          userInfo.u_mobile
        }}！👋</span>
      <span style="font-size: 19px;color: #333333;margin-top: 40px;font-weight: bold">欲言，畅所欲言！</span>
      <span style="font-size: 14px;color: #666666;margin-top: 20px;">立即与小言开始对话吧，来提升创造力！</span>
    </div>
    <div v-if="list.length > 0" class="mobile mobile-flex">
      <div v-for="(item, index) in list" :key="index" class="mobile-cell" @click="_toDetailPage(item)">
        <img v-if="index % 3 === 0" class="img-cell" src="../../src/assets/images/mobile/cell-0.png" alt="cell0"/>
        <img v-if="index % 3 === 1" class="img-cell" src="../../src/assets/images/mobile/cell-1.png" alt="cell1"/>
        <img v-if="index % 3 === 2" class="img-cell" src="../../src/assets/images/mobile/cell-2.png" alt="cell2"/>
        <div class="mobile-cell-right">
          <span class="mobile-txt">{{ item.cl_name }}</span>
          <span style="font-size: 13px; color: #999999">{{ item.cl_create_time }}</span>
        </div>
        <div class="span-delete" @click="_deleteChatListOut(item)">
          <i class="icon iconfont icon-shanchu" style="color: #F75555;font-size: 26px;min-width: 30px;"></i>
        </div>
      </div>
    </div>
    <div class="mobile-bottom-btn-welcome" @click="_toDetailPage(undefined)">
      <span>开始与小言对话</span>
    </div>
  </div>
</template>

<script>
import http from "@/util/http"
import Message from "vue-m-message"
import 'vue-m-message/dist/style.css'
import {EventSourcePolyfill} from "event-source-polyfill"

import Cookies from "js-cookie";
const {body} = document
let interval
export default {
  name: "FirstPage",
  components: {},
  data() {
    return {
      totalTimesInterval: 0,
      screenWidth: 1920,
      isMobile: false,
      showEdit: false,
      showQRCode: false,
      showAlert: false,
      showSetting: false,
      showPay: false,
      showHistory: false,
      showPayResult: false,
      onCommitting: false,
      isNewAdd: false,
      userInfo: {},
      domPay: undefined,
      tokenCostLists: [],
      parResultInfo: {
        pp_kit_name: '',
        pp_order_id: '',
        pp_pay_success_time: '',
        pp_price: ''
      },
      yuE: 0,
      list: [],
      listChat: [],
      listPay: [
        {
          id: 0,
          des: "",
          pay: 58,
          select: true
        },
        {
          id: 1,
          des: "多送10元",
          pay: 178,
          select: false
        },
        {
          id: 2,
          des: "多送30元",
          pay: 388,
          select: false
        }
      ],
      queryData: {
        content: undefined,
        title: undefined,
        id: undefined
      },
      queryPayData: {
        payId: undefined,
        pay: undefined,
        des: undefined,
        out_trade_no: undefined
      },
      listTokensTime: [
        {
          label: '近1个月',
          type: 1,
          select: true
        },
        {
          label: '近3个月',
          type: 2,
          select: false
        },
        {
          label: '近半年',
          type: 3,
          select: false
        }
      ],
      listExamples: {
        banGong: {
          label: '办公',
          data: [
            {
              content: '用C语言写一个傅里叶变换'
            },
            {
              content: '写一个不少于1000字的年会发言稿'
            },
            {
              content: '如何处理同事间的合作关系'
            }
          ]
        },
        shengHuo: {
          label: '生活',
          data: [
            {
              content: '如何做一锅美味的红烧肉'
            },
            {
              content: '酸梅汤的做法'
            },
            {
              content: '如何去除烧水壶中的水垢'
            }
          ]
        },
        xueXi: {
          label: '学习',
          data: [
            {
              content: '解析一下麦当劳的数字转型'
            },
            {
              content: '分析一下美的成功的原因'
            },
            {
              content: '给一个一元二次方程的解法'
            }
          ]
        }
      }
    }
  },
  mounted() {
    this._loginStatus()

    this._payInfoList()

    const queryData = this.$route.query

    if (Object.prototype.hasOwnProperty.call(queryData, 'out_trade_no') === true) {
      this.queryPayData.out_trade_no = queryData.out_trade_no
      this._queryOrder()
    }
  },
  unmounted() {
    interval && clearInterval(interval)
  },
  beforeMount() {
    this._resizeHandler()
    window.addEventListener('resize', this._resizeHandler)
  },
  methods: {
    _showOrHideQRCode() {
      this.showQRCode = !this.showQRCode
    },
    _selectExample(e) {
      if (this.onCommitting) {
        Message.warning("请等待回复")
        return
      }
      this.queryData.content = e.content
      this.listChat = []
      this._sseDoChat()
    },
    _addNewChatList() {
      this.queryData.id = undefined
      this.isNewAdd = true
      this.listChat = []
      for (const i in this.list) {
        this.list[i].select = false
      }
    },
    _deleteChatListOut(e) {
      this._showOrHideAlert()

      this.queryData.id = e.cl_id

      this._handlePrevent()
    },
    _editChatListName(e) {
      this._showOrHideEdit()
      this.queryData.title = e.cl_name
      this.queryData.id = e.cl_id
      this._handlePrevent()
    },
    _showOrHideEdit() {
      this.showEdit = !this.showEdit

      if (!this.showEdit) {
        this._resetQueryEdit()
      }
    },
    _showOrHideSetting() {
      this.showSetting = !this.showSetting
    },
    _showOrHidePay() {
      this.showPay = !this.showPay

      if (this.showPay) {
        this._payInfoList()
        this._resetPayResult()
      }
    },
    _showOrHidePayResult() {
      this.showPayResult = !this.showPayResult

      if (!this.showPayResult) {
        this._resetPayResult()
      }
    },
    _showOrHideHistory() {
      this.showHistory = !this.showHistory

      if (this.showHistory) {
        for (const i in this.listTokensTime) {
          this.listTokensTime[i].select = parseInt(i) === 0
        }

        this._tokensCostListDay()
      }
    },
    _showOrHideAlert() {
      this.showAlert = !this.showAlert

      if (!this.showAlert) {
        this._resetQueryEdit()
      }
    },
    _resetQueryEdit() {
      this.queryData.id = undefined
      this.queryData.title = undefined
    },
    _resizeHandler() {
      const rect = body.getBoundingClientRect()
      if (rect.width <= 767) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
      this.screenWidth = rect.width
    },
    _setClipboard(content) {
      Message.closeAll()
      this.$copyText(content)
      Message.success('复制成功')
    },
    _toLoginPage() {
      this.$router.push({
        path: '/login'
      })
    },
    _toShareToBuyTimePage() {
      const routeData = this.$router.resolve({
        path: '/ShareToBuyTime'
      });
      window.open(routeData.href, '_blank');
    },
    _toSettingPage() {
      this.$router.push({
        path: '/SettingPage'
      })
    },
    _toTongJiPage() {
      this.$router.push({
        path: '/TongJi'
      })
    },
    _toDetailPage(e) {
      let query = {
        id: undefined,
        title: undefined
      }

      if (e) {
        query = {
          id: e.cl_id,
          title: e.cl_name
        }
      }

      this.$router.push({
        path: '/ChatDetail',
        query: query
      })
    },
    _payInfoList() {
      http.get('payInfoList')
          .then((res) => {
            if (res.data.status === 0) {
              this.listPay = []
              for (const i in res.data.data) {
                const obj = {
                  id: res.data.data[i].pk_id,
                  des: res.data.data[i].pk_des,
                  pay: res.data.data[i].pk_price / 100,
                  select: parseInt(i) === 0
                }

                this.listPay.push(obj)
              }

              this.queryPayData.des = this.listPay[0].des
              this.queryPayData.pay = this.listPay[0].pay
              this.queryPayData.payId = this.listPay[0].id
            }
          })
    },
    _loginStatus() {
      http.get('loginStatus')
          .then((res) => {
            if (res.data.status === 0) { // 已登录
              this._userInfo()
              this._userYuE()
              this._chatList()
            } else { // 尚未登录
              this._toLoginPage()
            }
          })
    },
    _queryOrder() {
      http.get('queryOrder', {
        orderId: this.queryPayData.out_trade_no
      })
          .then((res) => {
            if (res.data.status === 0) {
              this._queryOrderDetail()
            } else if (res.data.status === 301) {
              this._queryOrderDetail()
            }
          })
    },
    _queryOrderDetail() {
      http.get('orderDetail', {
        orderId: this.queryPayData.out_trade_no
      })
          .then((res) => {
            if (res.data.status === 0) {
              this.parResultInfo = res.data.data
            }
            this._userYuE()
            if (this.showPayResult) {
              return
            }
            this._showOrHidePayResult()
          })
    },
    _selectItemToken(e) {
      const label = e.label
      for (const i in this.listTokensTime) {
        if (this.listTokensTime[i].label === label) {
          this.listTokensTime[i].select = true
        } else {
          this.listTokensTime[i].select = false
        }
      }

      this._tokensCostListDay()
    },
    _userYuE() {
      http.get('userYuE')
          .then((res) => {
            if (res.data.status === 0) {
              this.yuE = res.data.data
            }
          })
    },
    _resetListChat() {
      this.listChat = []
    },
    _handleScrollBottom() { // 滚动到底部
      this.$nextTick(() => {
        let scrollElem = this.$refs.scrollDiv;
        scrollElem.scrollTo({top: scrollElem.scrollHeight, behavior: 'smooth'})
      })
    },
    _changeDateFormat(date, fmt) {
      const o = {
        "M+": date.getMonth() + 1, // 月份
        "d+": date.getDate(), // 日
        "h+": date.getHours(), // 小时
        "m+": date.getMinutes(), // 分
        "s+": date.getSeconds(), // 秒
        "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
        "S": date.getMilliseconds() // 毫秒
      }
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length))
      for (const k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)))
      return fmt
    },
    _sseDoChat(){
      if (this.onCommitting) {
        Message.warning("请等待回复")
        return
      }

      if (!this.queryData.content) {
        Message.warning("问题不能为空")
        return
      }

      const queryContent = JSON.parse(JSON.stringify(this.queryData.content))
      this.queryData.content = undefined
      let queryId = ''
      if (this.queryData.id) {
        queryId = this.queryData.id
      }
      this.onCommitting = true
      Message.closeAll()
      // 设置等待答案过程中的状态交互
      Message.loading("正在拼命获取答案...", {
        duration: -1
      })

      this.listChat.push({
        isMine: 1,
        name: '我',
        content: queryContent,
        id: undefined,
        type: undefined,
        createTime: this._changeDateFormat(new Date(), "yyyy-MM-dd hh:mm:ss")
      })

      const answer = {
        isMine: 0,
        name: '答',
        content: '正在拼命获取答案...',
        id: undefined,
        type: undefined,
        createTime: this._changeDateFormat(new Date(), "yyyy-MM-dd hh:mm:ss")
      }

      this.listChat.push(answer)
      // end
      this._handleScrollBottom()
      this.isNewAdd = false // 显示实际内容，屏蔽新增提交页面显示
      const that = this
      if (window.EventSource) {
        const eventSource = new EventSourcePolyfill(
            "https://yuyan.mfang.xyz/api/v1/commitSSE?id=" + queryId + "&content=" + encodeURIComponent(queryContent), {
              // 设置重连时间
              heartbeatTimeout: 60 * 60 * 1000,
              timeout: 60 * 60 * 1000,
              // 添加token
              headers: {
                'Box-From': 0,
                'chatgpt-token': Cookies.get('token')
              },
            });
        /*
         * open：订阅成功（和后端连接成功）
         */
        eventSource.addEventListener('open', function () {
          Message.closeAll()
        });
        /*
        * message：后端返回信息，格式可以和后端协商
        */
        eventSource.addEventListener('receiveData', function (e) {
          const dataR = JSON.parse(e.data)
          if (dataR.status === 0) {
            if (that.listChat[that.listChat.length - 1].content === '正在拼命获取答案...') {
              that.listChat[that.listChat.length - 1].content = ''
            }
            that.listChat[that.listChat.length - 1].content = that.listChat[that.listChat.length - 1].content + dataR.content
          } else if (dataR.status === 9000) { // 结束问答
            const answer = {
              isMine: dataR.data.answer.cd_is_answer === 0,
              name: dataR.data.answer.cd_is_answer === 1 ? '答' : '我',
              content: dataR.data.answer.cd_content,
              id: dataR.data.answer.cd_id,
              type: dataR.data.answer.cd_type,
              createTime: dataR.data.answer.cd_create_time,
              totalTokens: dataR.data.answer.cd_total_tokens
            }
            that.listChat[that.listChat.length - 1] = answer
            that._userYuE()
            that.onCommitting = false

            if (queryId === '') {
              that._chatList()
            }
          } else {
            Message.error(dataR.content)
            that.onCommitting = false
          }
          that._handleScrollBottom()
          return 'receive'
          // const { status, msg } = e.data
        });

        /*
        * error：错误（可能是断开，可能是后端返回的信息）
        */
        eventSource.addEventListener('error', function (err) {
          console.log(err);
          // 类似的返回信息验证，这里是实例
          err && err.status === 401 && console.log('not authorized')

          eventSource.close();
        })
        /*
        * close：关闭（是断开，可能是后端返回的信息）
        */
        eventSource.addEventListener('close', function (res) {
          console.log(res);

          eventSource.close();
        })
      } else {
        console.log("你的浏览器不支持SSE~")
      }
    },
    _doTheChatDetail() {
      if (this.onCommitting) {
        Message.warning("请等待回复")
        return
      }

      if (!this.queryData.content) {
        Message.warning("问题不能为空")
        return
      }

      const queryContent = JSON.parse(JSON.stringify(this.queryData.content))
      this.queryData.content = undefined
      this.onCommitting = true
      Message.closeAll()
      // 设置等待答案过程中的状态交互
      Message.loading("正在拼命获取答案...", {
        duration: -1
      })

      this.listChat.push({
        isMine: 1,
        name: '我',
        content: queryContent,
        id: undefined,
        type: undefined,
        createTime: this._changeDateFormat(new Date(), "yyyy-MM-dd hh:mm:ss")
      })

      const answer = {
        isMine: 0,
        name: '答',
        content: '正在拼命获取答案...',
        id: undefined,
        type: undefined,
        createTime: this._changeDateFormat(new Date(), "yyyy-MM-dd hh:mm:ss")
      }

      this.listChat.push(answer)
      // end
      this._handleScrollBottom()
      this.isNewAdd = false // 显示实际内容，屏蔽新增提交页面显示
      http.post('commitAnswer', {
        id: this.queryData.id,
        content: queryContent
      })
          .then((res) => {
            Message.closeAll()
            this.onCommitting = false

            if (res) {
              if (res.data.status === 0) {
                this.listChat.splice(this.listChat.length - 1, 1)
                Message.success(res.data.content)
                if (!this.queryData.id) {
                  this._chatList()
                } else {
                  const resData = res.data.data.answer
                  const answer = {
                    isMine: resData.cd_is_answer === 0,
                    name: resData.cd_is_answer === 1 ? '答' : '我',
                    content: resData.cd_content,
                    id: resData.cd_id,
                    type: resData.cd_type,
                    createTime: resData.cd_create_time,
                    totalTokens: resData.cd_total_tokens
                  }
                  this.listChat.push(answer)
                }
                this._handleScrollBottom()
                this._userYuE()
              } else {
                Message.error(res.data.content)
              }
            } else {
              this._chatList()
              this._handleScrollBottom()
              this._userYuE()
            }
          })
          .catch((err) => {
            console.log(err)
            this._chatList()
            this._handleScrollBottom()
            this._userYuE()
          })
    },
    _selectItemPay(e) {
      for (const i in this.listPay) {
        if (this.listPay[i].id === parseInt(e.id)) {
          this.listPay[i].select = true
          this.queryPayData.des = this.listPay[i].des
          this.queryPayData.payId = this.listPay[i].id
          this.queryPayData.pay = this.listPay[i].pay
        } else {
          this.listPay[i].select = false
        }
      }
    },
    _logOut() {
      http.post('logOut')
          .then((res) => {
            if (res.data.status === 0) {
              Message.success(res.data.content)
              setTimeout(() => {
                this.$router.push({
                  path: '/'
                })
              }, 1000)
            } else {
              Message.error(res.data.content)
            }
          })
    },
    _deleteChatList() {
      Message.loading("删除中")
      http.post('deleteChatList', {
        id: this.queryData.id
      })
          .then((res) => {
            Message.closeAll()
            if (res.data.status === 0) {
              Message.success(res.data.content)
              this._chatList()
              // for (const i in this.list) {
              //   if (this.list[i].cl_id === this.queryData.id && this.list[i].select) {
              //     this._chatList()
              //     break
              //   }
              // }
              // for (const i in this.list) {
              //   if (this.list[i].cl_id === this.queryData.id) {
              //     this.list.splice(i, 1)
              //     break
              //   }
              // }

              this._showOrHideAlert()
            } else {
              Message.error(res.data.content)
            }
          })
    },
    _toChongZhiPage() {
      this.$router.push({
        path: '/ChongZhi'
      })
    },
    _chatList() {
      Message.loading("获取数据...")
      http.get('chatList')
          .then((res) => {
            Message.closeAll()
            if (res.data.status === 0) { // 已登录
              for (const i in res.data.data) {
                if (parseInt(i) === 0) {
                  res.data.data[i].select = true
                  this.queryData.id = res.data.data[i].cl_id
                  this._chatDetail()
                } else {
                  res.data.data[i].select = false
                }
              }
              this.list = res.data.data
              this._resetListChat()
            }
          })
    },
    _chatDetail() {
      Message.closeAll()
      Message.loading("获取数据...")
      http.get('chatDetail', {
        id: this.queryData.id
      })
          .then((res) => {
            Message.closeAll()
            if (res.data.status === 0) {
              const listResult = []
              for (const i in res.data.data) {
                const resData = res.data.data[i]
                listResult.push({
                  isMine: resData.cd_is_answer === 0,
                  name: resData.cd_is_answer === 1 ? '答' : '我',
                  content: resData.cd_content,
                  id: resData.cd_id,
                  type: resData.cd_type,
                  createTime: resData.cd_create_time,
                  totalTokens: resData.cd_total_tokens
                })
              }

              this.listChat = listResult
              this._handleScrollBottom()
            }
          })
    },
    _userInfo() {
      http.get('userInfo')
          .then((res) => {
            if (res.data.status === 0) {
              this.userInfo = res.data.data
            }
          })
    },
    _tokensCostListDay() {
      Message.closeAll()
      Message.loading("加载中")
      let queryTypeTimes = undefined
      for (const i in this.listTokensTime) {
        if (this.listTokensTime[i].select) {
          queryTypeTimes = this.listTokensTime[i].type

          break
        }
      }
      this.tokenCostLists = []
      http.get('tokensCostListDay', {
        timeType: queryTypeTimes
      })
          .then((res) => {
            Message.closeAll()
            if (res.data.status === 0) {
              this.tokenCostLists = res.data.data
            }
          })
    },
    _handleInput(e) {
      this.queryData.content = e.target.innerText
      // this.queryData.content = this.queryData.content.replace(/\n/g, '<br/>')
    },
    _selectListItem(item) {
      const id = item.cl_id
      this.isNewAdd = false
      for (const i in this.list) {
        if (this.list[i].cl_id === id) {
          this.list[i].select = true
          this.queryData.id = id
          this._chatDetail()
        } else {
          this.list[i].select = false
        }
      }
    },
    _handlePrevent() {
      event.stopPropagation()
    },
    _editChatList() {
      if (!this.queryData.title) {
        Message.error('标题必须填写')
      }
      Message.loading("提交中")
      http.post('editChatListName', {
        id: this.queryData.id,
        title: this.queryData.title
      })
          .then((res) => {
            Message.closeAll()
            if (res.data.status === 0) {
              Message.success(res.data.content)
              for (const i in this.list) {
                if (this.list[i].cl_id === this.queryData.id) {
                  this.list[i].cl_name = this.queryData.title
                  break
                }
              }
              this._showOrHideEdit()
            } else {
              Message.error(res.data.content)
            }
          })
    },
    _toDownloadPage() {
      this.$router.push({
        path: '/Download'
      })
    },
    _resetPayResult() {
      this.$router.push({query: {}})
      interval && clearInterval(interval)
    },
    _makePCPay() {
      Message.loading("提交中")
      http.post('makePay', {
        kitId: this.queryPayData.payId
      })
          .then((res) => {
            Message.closeAll()
            if (res.data.status === 0) {
              Message.success(res.data.content)
              const outTradeNo = res.data.data.outTradeNo
              let resData = res.data.data.data
              const resDataArray = resData.split("name='alipaysubmit'")
              resData = resDataArray[0] + ' target="_blank" name="alipaysubmit" ' + resDataArray[1]

              this.domPay = resData

              setTimeout(() => {
                document.forms['alipaysubmit'].submit()
                this.$router.push({
                  query: {
                    out_trade_no: outTradeNo
                  }
                })
                this._showOrHidePay()
                const that = this
                interval = setInterval(() => {
                  that.queryPayData.out_trade_no = outTradeNo
                  that._queryOrder()
                  that.totalTimesInterval = that.totalTimesInterval + 1
                  if (that.totalTimesInterval > 10) {
                    clearInterval(interval)
                  }
                }, 3999)

              }, 500)
            } else {
              Message.error(res.data.content)
            }
          })
    }
  }
}
</script>

<style scoped>
.abs-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}

.abs-con .abs-con-dialog {
  width: 400px;
  max-height: 500px;
  padding-bottom: 40px;
  border-radius: 20px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.abs-con-pay {
  padding: 10px 3% 10px 3%;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.abs-con-child:hover {
  border: 1px solid #11b1a5;
  background: rgba(17, 177, 165, 0.1);
}

.abs-con-child-select {
  border: 1px solid #11b1a5;
  background: rgba(17, 177, 165, 0.1);
}

.abs-con-child-un-select {
  border: 1px solid #f0f0f0;
}

.abs-con-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: relative;
  width: 166px;
  height: 100px;
  margin: 0 20px;
}

.abs-con-child .abs-con-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.abs-con-pay .abs-con-child .abs-con-bottom {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background: #11B1A5;
  color: #ffffff;
  font-size: 13px;
  height: 20px;
  width: 100%;
  border: 1px solid #11B1A5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  font-weight: bold;
}

.abs-con .abs-con-dialog-confirm {
  width: 380px;
  padding-bottom: 40px;
  border-radius: 20px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 4px 4px 5px #000000;
}

.abs-top {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 50px;
  min-height: 50px;
  background: #11B1A5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
}

.abs-con .abs-con-dialog .abs-input {
  width: 70%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #f0f0f0;
  margin-top: 30px;
  padding: 0 20px;
}

.abs-btn {
  margin-top: 30px;
  width: 200px;
  height: 40px;
  border-radius: 20px;
  background: #11B1A5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.abs-btn-other-con {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}

.abs-btn-other {
  margin: 0 20px;
  width: 120px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.con-out {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
}

.con-out .left-con {
  width: 230px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #333333;
}

.left-con-inner {
  height: 100%;
  width: 230px;
  overflow-y: scroll;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.left-con-inner::-webkit-scrollbar {
  display: none;
}

.right-child-con::-webkit-scrollbar {
  display: none;
}

.con-out .right-con {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /*background: rgba(17, 177, 165, 0.9);*/
  background: #ffffff;
}

.con-out .right-con .right-center-con {
  width: 80%;
  min-width: 400px;
  max-width: 900px;
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  margin: 20px 0 80px 0;
  overflow-y: scroll;
}

.d-row .d-row-left {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.d-row .span-row {
  margin: 0 10px;
  font-size: 22px;
  cursor: pointer;
}

.left-con .d-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #ffffff;
  cursor: pointer;
}

.d-cell:hover {
  background: rgba(122, 122, 122, 0.4);
  border-radius: 6px;
}

.round-row {
  margin: 20px 10px;
  padding: 14px 20px;
  border-radius: 10px;
  border: 1px dashed #f2f2f2;
  cursor: pointer;
  width: 70%;
}

.round-row:hover {
  background: rgba(122, 122, 122, 0.4);
}

.d-big-round {
  margin: 5px 10px;
  padding: 13px 12px;
  border-bottom: 1px solid rgba(122, 122, 122, 0.4);
  font-size: 14px;
  width: 80%;
}

.span-row-inner {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  text-align: start;
}

.d-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 60px;
  background: #ffffff;
  font-size: 15px;
  font-weight: bold;
  border-bottom: 1px solid #f2f2f2;
}

.right-center-con .right-child-con {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /*background: #11B1A5;*/
  width: 100%;
  border-radius: 10px;
  overflow-y: scroll;
}

.right-center-con::-webkit-scrollbar {
  display: none;
}

.right-center-con .right-child-con-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 0 10px 0;
  background: #ffffff;
  border-radius: 10px;
  min-height: 40px;
  /*box-shadow: 4px 5px 20px #999999;*/
}

.input-bottom-z {
  /*height: 40px;*/
  flex: 1;
  border-radius: 30px;
  margin: 0 30px 0 30px;
  border: 2px solid #f0f0f0;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  white-space: pre-wrap;
  /*box-shadow: 4px 4px 5px #999999;*/
}

.input-bottom {
  height: 40px;
  flex: 1;
  border-radius: 30px;
  margin: 0 30px 0 30px;
  border: 2px solid #f0f0f0;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  /*box-shadow: 4px 4px 5px #999999;*/
}

input:focus {
  outline: #11B1A5;
}

.send-btn-con {
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.send-btn {
  font-size: 28px;
  color: #666666;
}

.send-btn:hover {
  color: #11B1A5;
  cursor: pointer;
}

.chat-detail-cont {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 94%;
  padding: 10px 3%;
}

.detail-select {
  background: rgba(122, 122, 122, 0.4);
  border-radius: 6px;
  border-bottom: none;
}

.chat-detail-cont .chat-img-src {
  width: 50px;
  height: 50px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
}

.chat-detail-cont .chat-detail-inner {
  margin: 0 20px;
  border-radius: 10px;
  background: #f2f2f2;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 1;
  max-width: 742px;
}

.chat-detail-cont .chat-detail-inner .chat-detail-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.chat-detail-top .pp {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  line-height: 30px;
  font-size: 16px;
  text-align: start;
  white-space: pre-wrap;
  width: 100%;
  word-break: break-all;
}

.chat-detail-top-left {
  flex: 1;
  color: #666666;
  font-size: 14px;
  display: flex;
}

.is-host {
  background: #0077aa;
}

.is-not-host {
  background: #11B1A5;
}

.copy-btn {
  font-size: 20px;
  color: #666666;
  cursor: pointer;
}

.example-biao-ti {
  margin: 40px 0 20px 0;
  font-weight: bold;
  font-size: 26px;
  color: #666666;
}

.example-con {
  width: 80%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px 0 10px 0;
}

.example-con .example-title {
  font-size: 20px;
  font-weight: bold;
  margin: 20px 0 10px 0;
  color: #333333;
}

.example-con .example-inner-con {
  width: 100%;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
}

.example-con .example-inner-con .example-inner-con-cell {
  width: 94%;
  border-radius: 10px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  color: #666666;
  font-size: 14px;
  cursor: pointer;
}

.example-span:hover {
  color: #11B1A5;
  font-weight: bold;
}

.example-con .example-inner-con .example-inner-con-cell .example-span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  flex: 1;
  font-size: 16px;
}

.setting-con {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #f2f2f2;
  width: 80%;
  height: 40px;
  color: #666666;
  cursor: pointer;
}

.setting-con .setting-con-flex {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.qr-container {
  padding: 20px 0;
  margin-top: 10px;
  background: #f2f2f2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.qrcode-img {
  width: 160px;
  height: 160px;
  border-radius: 4px;
  margin-right: 30px;
}

.small-money {
  font-size: 16px;
  font-weight: bold;
}

.small-money-size {
  font-size: 26px;
  font-weight: bold;
  color: #11B1A5;
}

.big-money {
  font-size: 30px;
  font-weight: bold;
}

.big-money-z {
  font-size: 40px;
  font-weight: bold;
  color: #11B1A5;
}

.money-con {
  margin-bottom: 20px;
}

.pay-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.pay-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 230px;
}

.vip-con {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
  cursor: pointer;
}

.pay-cost-con {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  margin-top: 10px;
}

.token-con {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  padding: 0 3%;
}

.token-con-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  padding: 0 3%;
  height: 100%;
  overflow-y: scroll;
}

.token-con-column .token-con-column-cell {
  font-size: 18px;
  font-weight: bold;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.token-con .token-con-cell {
  width: 100px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #11B1A5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 20px 0 0;
  cursor: pointer;
}

.token-con-cell:hover {
  color: #ffffff;
  font-weight: bold;
  background: #11B1A5;
}

.token-con-cell-select {
  color: #ffffff;
  font-weight: bold;
  background: #11B1A5;
}

.token-con-cell-un-select {
  color: #11B1A5;
  background: #ffffff;
}

.token-cost-con {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  min-height: 60px;
  width: 90%;
  padding: 0 2%;
  border-bottom: 1px solid #f0f0f0;
}

.absolute-aa {
  min-width: 500px;
  height: 500px;
}

.mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.mobile .mobile-big-logo {
  width: 70%;
  height: auto;
}

.mobile .mobile-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.mobile-row-first-line {
  height: 30px;
  padding: 0 24px;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mobile-small-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.mobile-row-first-line .mobile-row-first-line-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  height: 100%;
  font-weight: bold;
  color: #333333;
  font-size: 18px;
}

.span-row-mobile {
  font-size: 26px;
  margin-right: 6px;
}

.mobile-flex {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.mobile-bottom-btn-welcome {
  width: 60%;
  height: 50px;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #11B1A5;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
}

.mobile-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 24px;
  max-width: 80%;
  min-width: 80%;
  border-radius: 10px;
  margin: 10px 0;
  background: #ffffff;
}

.img-cell {
  width: 100px;
  height: auto;
  margin-right: 10px;
}

.mobile-cell-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  flex: 1;
  height: 100%;
}

.mobile-cell-right .mobile-txt {
  font-size: 18px;
  color: #666666;
  /*font-weight: bold;*/
  line-height: 30px;
  text-align: start;
  width: 100%;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.span-delete {
  height: 100%;
  width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.abs-con-dialog-confirm-mobile {
  width: 80%;
  border-radius: 30px;
  background: #ffffff;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.abs-btn-other-mobile {
  width: 70%;
  height: 50px;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}

.pay-cell-alipay {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 18px;
  background: #1890ff;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
}

.i-size {
  font-size: 30px;
}

.i-result {
  font-size: 28px;
  font-weight: bold;
  color: #666666;
  margin-left: 20px;
}

.close-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
  background: #11B1A5;
  padding: 0 20px;
  height: 40px;
  border-radius: 25px;
  margin-top: 30px;
  cursor: pointer;
}

.share-con {
  width: 90px;
  height: 30px;
  border-radius: 15px;
  color: #ffffff;
  background: #11B1A5;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.qrcode-con {
  position: relative;
  margin-right: 20px;
}

.qrcode-con-child {
  position: absolute;
  left: 100px;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 5px 8px #666666;
}

.qrcode-con-child-img {
  width: 95%;
  height: 95%;
}
.zz-textarea::-webkit-scrollbar {
  display: none;
}
d-textarea::-webkit-scrollbar {
  display: none;
}
</style>