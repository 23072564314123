import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FirstPage from "@/views/FirstPage"
import ChatDetail from "@/views/mobile/ChatDetail"
import Setting from "@/views/mobile/Setting"
import TongJi from "@/views/mobile/TongJi"
import ChongZhi from "@/views/mobile/ChongZhi"
import ShareToBuyTime from "@/views/ShareToBuyTime"
import Download from "@/views/mobile/Download";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/FirstPage',
    name: 'FirstPage',
    component: FirstPage
  },
  {
    path: '/ChongZhi',
    name: 'ChongZhi',
    component: ChongZhi
  },
  {
    path: '/Download',
    name: 'Download',
    component: Download
  },
  {
    path: '/ChatDetail',
    name: 'ChatDetail',
    component: ChatDetail
  },
  {
    path: '/SettingPage',
    name: 'SettingPage',
    component: Setting
  },
  {
    path: '/TongJi',
    name: 'TongJi',
    component: TongJi
  },
  {
    path: '/ShareToBuyTime',
    name: 'ShareToBuyTime',
    component: ShareToBuyTime
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
