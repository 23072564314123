import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Message from 'vue-m-message'
import '../src/assets/fonts/iconfont.css'
import VueClipboard from 'vue-clipboard2'
import TextArea from 'vue-devui/textarea'
import 'vue-devui/button/style.css'

createApp(App).use(store).use(VueClipboard).use(TextArea).use(Message).use(router).mount('#app')
